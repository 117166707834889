import axios from 'axios';

const api_base_url = "https://server.mafire.site/";
const image_base_url = "https://bucket.mafire.site/"

export const testimonial = async (data) => {
  try {

    const response = await axios.post(`${api_base_url}api/mafire-testimonial`, data);
    return response.data;

  } catch (error) {

    throw error;

  }
};

export const uploadImage = async (data) => {
  try {

    const response = await axios.post(`${image_base_url}api/upload-image`, data);
    return response.data;

  } catch (error) {

    throw error;

  }
};

export const contactForm = async (data) => {
  try {
    const response = await axios.post(`${api_base_url}api/mafire-contact-form`,data);
    return response.data;

  } catch (error) {

    throw error;

  }
};

export const subcribeUs = async (data) => {
  try {
    const response = await axios.post(`${api_base_url}api/mafire-subscribe`,data);

    return response.data;

  } catch (error) {

    throw error;

  }
};

export const consultancy = async (data) => {
  try {
    const response = await axios.post(`${api_base_url}api/mafire-consultant`,data);

    return response.data;

  } catch (error) {

    throw error;

  }
};

export const getTestimonial = async (data) => {
  try {
    const response = await axios.get(`${api_base_url}api/mafire-testimonial`);

    return response.data;

  } catch (error) {

    throw error;

  }
};
