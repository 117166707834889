import 'animate.css';
import Master from './layout/MasterLayout';
import ComingSoonModal from "./extra/ComingSoonModal"

export default function News() {
  return (
    <Master
      child = {
        <>
          <ComingSoonModal/>
        </>
      }
    />
  )
}
