import 'animate.css';
import { technilogies } from '../array';

export default function Technologies() {
  return (
    <div id="technologies" className="hidden py-10 my-10 bg-white rounded-[30px] sm:block">
      <div className="flex flex-col justify-center px-6 mx-auto max-w-7xl lg:px-8">
        <div className="max-w-2xl mx-auto lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Technologies
          </p>
          <p className="mt-6 text-2xl font-semibold leading-8 text-gray-500">
            Areas we work on.
          </p>
        </div>
        <div className="max-w-4xl mx-auto mt-16">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {technilogies.map((item, index) => (
                  <li key={index} className="col-span-1 bg-white border rounded-lg shadow border-slate-100">
                    <div className="flex items-center justify-between w-full p-6 space-x-6">
                      <img className="flex-shrink-0 w-16 h-16" src={item.imageUrl} alt="" />
                      <div className="flex-1 truncate">
                      
                        <div className="flex items-center space-x-3">
                          <h3 className="text-sm font-medium text-gray-900 truncate">{item.name}</h3>
                        </div>
                        <p className="mt-1 text-sm text-gray-500 truncate">{item.title}</p>
                      </div>

                    </div>
                    <div>
                    </div>
                  </li>
                ))}
              </ul>
        </div>
      </div>
    </div>
  )
}
