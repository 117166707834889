export default function ComingSoon() {
    return (
      <>
        <div className="grid min-h-full px-6 py-24 bg-gray-700 place-items-center sm:py-32 lg:px-8">
          <div className="text-center sm:max-w-[750px] rounded-md bg-white p-10">
            {/* <p className="text-base font-semibold">Welcome To MaFa</p> */}
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Coming Soon</h1>
            <p className="mt-6 text-base leading-7 text-gray-600 hidden sm:flex ls:flex">MaFa is the ideal business solution for individuals seeking to expand their market reach to a global scale. It is especially beneficial for small businesses and freelancers looking to showcase their portfolios, as well as institutions aiming to streamline their record-keeping processes.</p>
            <div className="flex items-center justify-center mt-10 gap-x-6">
              <a href="mailto: saif.jamali03@gmail.com" className="text-sm font-semibold text-gray-900">
                Contact Us <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
  