import { Route, Routes, BrowserRouter  } from 'react-router-dom';
import routes from "./Routes";

export default function App() {
  return (
      <BrowserRouter >
        <Routes>
          {routes.map((route, index) => ( <Route key={index} path={route.path} element = {route.component}/> ))}
        </Routes>
      </BrowserRouter>
  );
}
