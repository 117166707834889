import {CreditCardIcon ,HomeIcon, WalletIcon, ChatBubbleLeftRightIcon, SparklesIcon } from '@heroicons/react/24/outline'

export default function Footer() {
    return (
        <>
          <div className="relative flex justify-between w-full h-12 gap-6 px-5 pt-2 bg-white shadow-lg sm:hidden lg:hidden rounded-t-3xl animate__animated animate__backInUp">
            <div className='flex gap-6 text-gray-800'>
              <a href="/" className="flex flex-col items-center px-1 pt-1 text-xs font-thin">
                <HomeIcon className="w-4 h-4"/>
                Home
              </a>

              <a href="/samples" className="flex flex-col items-center px-1 pt-1 text-xs font-thin">
                <WalletIcon className="w-4 h-4"/>
                Samples
              </a>
            </div>
            
            <a href="/contact-us" className="w-16 h-16 rounded-full text-white bg-[#38B6FF] border-[6px] top-0 transform -translate-x-1/2 -translate-y-1/2 left-1/2 border-slate-300 absolute flex justify-center items-center">
              <ChatBubbleLeftRightIcon className="w-6 h-6 text-white"/> 
            </a>

            <div className='flex gap-8 text-gray-800'>
              <a href="/review-us" className="flex flex-col items-center px-1 pt-1 text-xs font-thin">
                <SparklesIcon className="w-4 h-4"/>
                Review
              </a>
              <a href="/blogs" className="flex flex-col items-center px-1 pt-1 text-xs font-thin">
                  <CreditCardIcon className="w-4 h-4"/>
                Blogs
              </a>
            </div>
          </div>
        </>
    )
  }