const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'Packages', href: '/packages', current: false },
  { name: 'Samples', href: '/samples', current: false },
  { name: 'Blogs', href: '/blogs', current: false },
  { name: 'Review US', href: '/review-us', current: false },
  // { name: 'About Us', href: '#', current: false },
  // { name: 'Contact Us', href: '#contactus', current: false },
]

export default function Header({title}) {
  return (
    
    <>
      <div className="bg-slate-300 sm:hidden lg:hidden">
        <div className="flex justify-center gap-6 bg-white rounded-b-3xl animate__animated animate__backInDown">
            <p className="py-3 text-lg font-bold"> {title} </p>
        </div>
      </div>

      <div as="nav" className="hidden sm:block lg:block bg-slate-300">
        <div className="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8 bg-white rounded-b-3xl py-8">
          <div className="relative">
              <div className="flex items-center justify-between px-2 lg:px-0">
                <div className="flex-shrink-0">
                    <img
                    className="w-[150px]"
                      src="/images/Mafire-logo.png"
                      alt="Mafire"
                    />
                </div>
                <div className="hidden lg:ml-10 lg:block">
                    <div className="flex space-x-4">
                        {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="px-3 py-2 font-medium text-gray-600 rounded-md hover:underline hover:bg-opacity-75">
                            {item.name}
                            </a>
                        ))}
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
    
  )
}