import { GlobeAltIcon, UserGroupIcon, RectangleStackIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

export const services_array = [
    { 
        name: 'Landing Page', img: `/images/services-images/1.png`, description: `Your Landing page with more than 5 sections. Are you looking to make a striking first impression, captivate your audience, and drive conversions? Look no further! Our expert team specializes in creating stunning and effective landing pages tailored to your unique needs. A well-crafted landing page can be the gateway to success for your online marketing campaigns, and we're here to ensure your business stands out in the digital landscape.`
        ,points : [
            {title:"Hero Section" , detail: "The first thing visitors see, highlighting your main message and CTA."},
            {title:"Product or Service Description", detail:"Detailed information about what you offer."},
            {title:"Testimonials", detail: "Social proof to build trust."},
            {title:"Features and Benefits", detail:"Highlighting what sets you apart."},
            {title: "Pricing or Offer", detail: "Clear information on costs or promotions."},
            {title:"Contact/CTA", detail: "Encouraging visitors to take the desired action."},
            {title:"Footer",detail:"Additional information and navigation links."}
        ] 
    },
    { name: 'Portfolio', img: `/images/services-images/2.png`, description: 'Interactive portfolio to grab clients and show case you skills and projects'
    ,points : [
        {title:"Hero Section" , detail: "The first thing visitors see, highlighting your main message and CTA."},
        {title:"Product or Service Description", detail:"Detailed information about what you offer."},
        {title:"Testimonials", detail: "Social proof to build trust."},
        {title:"Features and Benefits", detail:"Highlighting what sets you apart."},
        {title: "Pricing or Offer", detail: "Clear information on costs or promotions."},
        {title:"Contact/CTA", detail: "Encouraging visitors to take the desired action."},
        {title:"Footer",detail:"Additional information and navigation links."}
    ]  },
    { name: 'Website Maintenance', img: `/images/services-images/3.png`, description: 'Maintain your website'
    ,points : [
        {title:"Hero Section" , detail: "The first thing visitors see, highlighting your main message and CTA."},
        {title:"Product or Service Description", detail:"Detailed information about what you offer."},
        {title:"Testimonials", detail: "Social proof to build trust."},
        {title:"Features and Benefits", detail:"Highlighting what sets you apart."},
        {title: "Pricing or Offer", detail: "Clear information on costs or promotions."},
        {title:"Contact/CTA", detail: "Encouraging visitors to take the desired action."},
        {title:"Footer",detail:"Additional information and navigation links."}
    ]  },
    { name: 'Domain Hosting', img: `/images/services-images/4.png`, description: 'Domain hosting on valuable prices'
    ,points : [
        {title:"Hero Section" , detail: "The first thing visitors see, highlighting your main message and CTA."},
        {title:"Product or Service Description", detail:"Detailed information about what you offer."},
        {title:"Testimonials", detail: "Social proof to build trust."},
        {title:"Features and Benefits", detail:"Highlighting what sets you apart."},
        {title: "Pricing or Offer", detail: "Clear information on costs or promotions."},
        {title:"Contact/CTA", detail: "Encouraging visitors to take the desired action."},
        {title:"Footer",detail:"Additional information and navigation links."}
    ]  },
]

export const testimonials_array = [
    {
        name: "John Doe",
        email: "john.doe@example.com",
        comment: "I recently purchased a new smartphone, and it has completely changed my life. The camera quality is amazing, and the battery life is outstanding. I'm so happy with my purchase!",
        img: `https://beingselfish.in/wp-content/uploads/2023/07/cute-girl-dp187.jpg`
    },
      {
        name: "Jane Smith",
        email: "jane.smith@example.com",
        comment: "I had an issue with my order, but the customer service team went above and beyond to resolve it quickly. Their responsiveness and commitment to customer satisfaction were truly exceptional. Thank you!",
        img: `https://beingselfish.in/wp-content/uploads/2023/07/cute-girl-dp187.jpg`
    },
      {
        name: "Bob Johnson",
        email: "bob.johnson@example.com",
        comment: "I've been using this website for a while now, and I must say it's the best one I've ever come across. The user interface is user-friendly, and the content is simply amazing. Keep up the good work!",
        img: `https://beingselfish.in/wp-content/uploads/2023/07/cute-girl-dp187.jpg`
    },
      {
        name: "Sara Williams",
        email: "sara.w@example.com",
        comment: "The product's quality is top-notch, and it's a great value for the price. I highly recommend it to anyone looking for a reliable and durable solution.",
        img: null
    },
      {
        name: "Michael Brown",
        email: "michael.b@example.com",
        comment: "I can't express how satisfied I am with the service. The support team was quick to assist me, and their expertise helped me solve my problem. I'm impressed and grateful for their help.",
        img: null
    },
      {
        name: "Emily Davis",
        email: "emily.d@example.com",
        comment: "This company sets the gold standard for customer service. I had a minor issue with my order, and they not only fixed it promptly but also sent me a complimentary gift as an apology. I'm blown away by their generosity!",
        img: null
    },
    {
        name: "James Wilson",
        email: "james.w@example.com",
        comment: "The product I bought exceeded my expectations. It's well-designed, user-friendly, and has all the features I was looking for. I'm one happy customer!",
        img: null
    },
    {
        name: "Linda Anderson",
        email: "linda.a@example.com",
        comment: "I'm a loyal customer of this brand, and for good reason. The consistent quality of their products and their commitment to customer satisfaction keep me coming back. Thank you for your outstanding service!",
        img: null
    },
    {
        name: "Richard Turner",
        email: "richard.t@example.com",
        comment: "I recently switched to this service, and I'm impressed with the ease of use and the speed of their platform. It has made my daily tasks much more efficient. Highly recommended!",
        img: null
    },
    {
        name: "Melissa Clark",
        email: "melissa.c@example.com",
        comment: "I've used many similar products in the past, but this one stands out. The attention to detail and the thoughtful design make it a joy to use. It's clear that the developers put a lot of effort into creating a great experience for users.",
        img:null
    }
]

export const packages_array = [
    {
        name: "Landing Page", description: "Grab your best package",
        packages: [
            {   type: "Basic", price: "15$", 
                description: [
                    { name: "1 designed section" },
                    { name: "Source File" },
                    { name: "Responsive design" },
                    { name: "3 revisions" },
                    { name: "Design according to your profession" },
                ] 
            },
            {   type: "Standard", price: "20$", 
                description:[
                    { name: "5 designed section" },
                    { name: "Source File" },
                    { name: "Responsive design" },
                    { name: "5 revisions" },
                    { name: "Design according to your profession" },
                ]
            },
            {   type: "Premium", price: "30$", 
                description: [
                    { name: "10 designed section" },
                    { name: "Source File" },
                    { name: "Responsive design" },
                    { name: "10 revisions" },
                    { name: "Design according to your profession" },
                    { name: "1 year of free hosting on our domain (our branding)" },
                ]
            }
        ]
    },
    {
        name: "Portfolio", description: "Grab your best package",
        packages: [
            {   type: "Basic", price: "40$", 
                description:[
                    { name: "1 Page" },
                    { name: "Responsive design" },
                    { name: "Source Code" },
                    { name: "3 revisions" },
                    { name: "Design according to your profession" },
                ] 
            },
            {   type: "Standard", price: "70$", 
                description:[
                    { name: "1 page" },
                    { name: "Responsive design" },
                    { name: "Source Code" },
                    { name: "Testimonials", detail:" (you’ll also get the option or page through which you can ask for the review from your client and you’ll able to see it on portfolio)"},
                    { name: "Modal or Popup for detail testimonial" },
                    { name: "5 revisions" },
                    { name: "Design according to your profession" },
                    { name: "1 year of free hosting on our domain (our branding)" },
                ]
            },
            {   type: "Premium", price: "100$", 
                description:[
                    { name: "Multipage" },
                    { name: "Responsive design" },
                    { name: "Animations" },
                    {name: "Testimonials", detail:"(you’ll also get the option or page through which you can ask for the review from your client and you’ll able to see it on portfolio)"},
                    {name: "Projects", detail:"(you’ll able to add any new project in your list and option to view your all project)"},
                    { name: "Source Code" },
                    { name: "Modals for details of project and testimonial" },
                    { name: "10 revisions" },
                    { name: "Design according to your profession" },
                    { name: "1 year of free hosting on our domain (our branding)" },
                ]}
        ]
    },
    {
        name: "Website", description: "Grab your best package",
        packages: [
            {   type: "Basic", price: "150$", 
                description: [
                    { name: "3 Pages" },
                    { name: "3 Revisions" },
                    { name: "Responsive design" },
                    { name: "Each page contains 4 sections (Max)" },
                ]
            },
            {   type: "Standard", price: "200$", 
                description: [
                    { name: "5 Pages" },
                    { name: "5 Revisions" },
                    { name: "Slide show for home page" },
                    { name: "Popups for any details" },
                    { name: "Responsive design" },
                    { name: "Source Code" },
                    { name: "Each Page can contain 4 sections (Max)" }
                ] 
            },
            {   type: "Premium", price: "250$", 
                description: [
                    { name: "10 Pages" },
                    { name: "Slide show for home page" },
                    { name:"Any Functionality on your demand", detail: "(like open any specific section on click etc)"},
                    { name: "Popups for any details" },
                    { name: "No limit of sections" },
                    { name: "Responsive design" },
                    { name: "10 Revisions" },
                    { name: "1 year of free hosting on our domain (our branding)" }
                ]
            }
        ]
    },
]

export const cards = [
    {
      title: 'Services',
      link: 'services',
      description: 'Visit our services to explore on which grounds we can help you',
      icon: GlobeAltIcon
   },
   {
      title: 'Testimonials',
      link: 'testimonials',
      description: 'Insight into the experiences and feedback shared by customers',
      icon: UserGroupIcon
   },
   {
      title: 'Packages',
      link: 'packages',
      description: 'Showcase of various pricing tiers and service options to guide you',
      icon: RectangleStackIcon
   },
   {
      title: 'About US',
      link: 'About-US',
      description: 'Who we are and our goals to change the world & work ease',
      icon: InformationCircleIcon
   },
]

export const technilogies = [
    {
      name: 'React Js',
      title: 'Programming language frame work',
      imageUrl: '/images/technologies/1.svg.jpg'
    },
    {
      name: 'Vue Js',
      title: 'Programming language frame work',
      imageUrl: '/images/technologies/2.svg.jpg'
    },
    {
      name: 'Figma',
      title: 'Web designing',
      imageUrl: '/images/technologies/4.svg.jpg'
    },
    {
        name: 'Tailwind',
        title: 'Website styling',
        imageUrl: '/images/technologies/3.svg.jpg'
    },
    {
      name: 'Bootstrap',
      title: 'Website styling',
      imageUrl: '/images/technologies/5.svg.jpg'    
    },
    // {
    //   name: 'Laravel',
    //   title: 'Regional Paradigm Technician',
    //   imageUrl:
    //     'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    // },
    // {
    //   name: 'Ruby on Rails',
    //   title: 'Regional Paradigm Technician',
    //   imageUrl:
    //     'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    // },
  ]
  