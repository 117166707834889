import 'animate.css';
import Master from './layout/MasterLayout';
import { useState,useEffect } from 'react';

export default function About() {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

  useEffect(() => { const handleResize = () => { setScreenSize({ width: window.innerWidth }) };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (screenSize.width > 640) {

    return (
      <div id="services" className="py-10 my-10 bg-white rounded-[30px]">
        <div className="flex flex-col justify-center px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              About
            </p>
          </div>
          <div className="flex max-w-4xl gap-6 mx-auto mt-16">
            <div>
              <img className="flex-shrink-0 p-1 bg-gray-200" src="/images/about-us.jpg" alt="" />
            </div>

            <div>

              <p className="mt-1 text-sm leading-6 text-gray-600 animate__rotateIn animate__animated">
              Welcome to Mafire, where we transform ideas into compelling online experiences. 
              As seasoned creators of websites, landing pages, portfolios, and dedicated to seamless website maintenance, 
              we are driven by a singular goal: to digitalize businesses and authentically represent individuals in the 
              ever-evolving digital landscape.

              <p className='mt-2'>
              At Mafire, we believe that a powerful online presence is not just a luxury but a necessity in today's competitive world. 
              With a team of skilled and innovative professionals, we bring your vision to life, crafting websites that not only 
              showcase your brand but also elevate your online identity. Whether you're a budding entrepreneur, a growing enterprise, 
              or an individual looking to make a mark, we tailor our services to meet your unique needs.
              </p>


              </p>
            </div>

          </div>
        </div>
      </div>
    )

  } else {

    return (
      <Master
        title="About Us"
        child = {
            <div className="flex flex-col gap-10 p-4 py-10 font-semibold bg-white my-10 rounded-sm max-h-[500px] overflow-y-scroll" >
                <img className="flex-shrink-0 p-1 bg-gray-200" src="/images/about-us.jpg" alt="" />

                <p className="mt-1 text-sm leading-6 text-gray-600">
                Welcome to Mafire, where we transform ideas into compelling online experiences. 
                As seasoned creators of websites, landing pages, portfolios, and dedicated to seamless website maintenance, 
                we are driven by a singular goal: to digitalize businesses and authentically represent individuals in the 
                ever-evolving digital landscape.

                <p className='mt-2'>
                At Mafire, we believe that a powerful online presence is not just a luxury but a necessity in today's competitive world. 
                With a team of skilled and innovative professionals, we bring your vision to life, crafting websites that not only 
                showcase your brand but also elevate your online identity. Whether you're a budding entrepreneur, a growing enterprise, 
                or an individual looking to make a mark, we tailor our services to meet your unique needs.
                </p>

                <p className='mt-2'>
                What sets us apart is our commitment to understanding your objectives. 
                We go beyond creating websites – we build digital experiences that resonate with your audience. 
                Our expertise lies in designing user-friendly interfaces, responsive layouts, and visually 
                stunning content that captivates and converts.
                </p>

                <p className='mt-2'>
                Maintenance is at the core of our services, ensuring that your online presence remains dynamic, 
                secure, and up-to-date. As technology evolves, so do we, staying ahead of trends to provide you with cutting-edge solutions.
                </p>

                <p className='mt-2'>
                Join us on the journey to digital transformation. 
                Let Mafire be the architect of your online success, 
                as we bring innovation, creativity, and technical prowess to every project. 
                Your story deserves to be told, and we're here to tell it – beautifully, seamlessly, and digitally.
                </p>


                </p>

            </div>
        }
      />
    )

  }
}
