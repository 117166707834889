import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import BerryRoutes from './BerryRoutes'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <React.StrictMode> <App /> </React.StrictMode> );

// const berry_root = ReactDOM.createRoot(document.getElementById('StickersBerry'));
// berry_root.render( <React.StrictMode> <BerryRoutes /> </React.StrictMode> );
