import 'animate.css';
import { useRef, useEffect, useState } from 'react';
import { testimonial } from '../api/api';
import { ArrowLeftIcon, CheckCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import Master from './layout/MasterLayout';
import SubmitButton from '../utils/button';
import ThankyouModal from '../utils/thankyouModal';
import { uploadImage } from '../api/api';

export default function Review() {

  const reviewForm = useRef('');
  const [submiting, is_submitting] = useState(false);
  const [is_open_thankyou, is_thankyou] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [imageName ,setImageName] = useState(null);
  const [is_image_upoading ,imageUploading] = useState(false);

  const handleFileChange = (event) => {
      imageUploading(true);
      handleImageUpload(event.target.files[0]);
  };

  const handleImageUpload = async (event) => {
    try {

      const formData = new FormData();
      formData.append('image', event);

      const response = await uploadImage(formData);
      setUserImage(response.image_url);
      setImageName(response.image);
      imageUploading(false);

    } catch (error) {
        imageUploading(false);
      alert('Error uploading image:', error.message);

    }
  };

  //Handle Resize 
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

  useEffect(() => { const handleResize = () => { setScreenSize({ width: window.innerWidth }) };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    is_submitting(true);
    const data = {};

    const formData = new FormData(e.target);

    formData.forEach((value, key) => {
      data[key] = value;
    });

    if(!data.rating) {
        data.rating = 0
    }
    data.image = imageName

    try {

      await testimonial(data);
      is_submitting(false);
      is_thankyou(true);
    
    } catch (error) {

      console.log(error);
      is_submitting(false);
    }
  };

    if (screenSize.width > 640) {
        return (
            <Master
                child = {
                    <div id="review_us" className="flex min-h-full px-5 py-10 pb-10 rounded-lg bg-slate-300">
                            <ThankyouModal 
                                is_open={is_open_thankyou}
                                icon={
                                    <CheckCircleIcon className="w-20 h-20 text-indigo-400" />
                                }
                                button={
                                    <a
                                        href="/"
                                        className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-400 rounded-md shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                    >
                                        Back To Home
                                    </a>
                                } 
                                message={
                                    <>
                                        <p className="text-2xl"> Thank you </p>
                                        <p> For submitting your review </p>
                                    </>

                                }  
                            />

                            <div className=" bg-white px-5 py-6 rounded-b-[40px] sm:rounded-[30px] shadow-lg animate__backInDown animate__animated">
                                <a href="/" className="flex text-xs sm:hidden">
                                    <ArrowLeftIcon className="w-4 h-4" />
                                </a>
                                <h2 className="mt-4 text-lg font-semibold leading-7 sm:text-4xl">Review Us</h2>
                                <p className="mt-1 text-xs leading-6 text-gray-400">Connect with us effortlessly – this is your gateway to seamless communication and personalized support.</p>
                            </div>
                            <form onSubmit={submit} ref={reviewForm} className="sm:flex sm:flex-col sm:justify-center sm:w-1/2 sm:p-5">
            
                                <div className="space-y-5">
            
                                    <div className="grid grid-cols-1 mt-10 gap-x-6 gap-y-5 sm:grid-cols-6">
                                        <div className="sm:col-span-6 md:col-span-5">
                                        <label htmlFor="mafa-client-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Name *
                                        </label>
                                        <div className="mt-2">
                                            <input required type="text" name="name" id="mafa-client-name" placeholder='Enter your name'
                                            className="block w-full px-4 py-1.5 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-300 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                        </div>
            
                                        <div className="sm:col-span-6 md:col-span-5">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Email *
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                required
                                                id="mafa-client-email"
                                                name="email"
                                                type="email"
                                                placeholder='Enter your email'
                                                autoComplete="email"
                                                className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-300 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                        </div>
            
                                        <div className="col-span-full">
                                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                            Review *
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                required
                                                id="about"
                                                name="message"
                                                rows={3}
                                                className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-300 sm:text-sm sm:leading-6"
                                                defaultValue={''}
                                            />
                                        </div>
                                        </div>
            
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            Image
                                        </label>
                                        <div className="flex mt-2">
                                            <label class="flex cursor-pointer items-center gap-x-5">

                                            <div className='overflow-hidden rounded-full'>
                                                {userImage ? (
                                                    <img className="flex-shrink-0 w-16 h-16" src={userImage} alt="" />
                                                ) : is_image_upoading ? (
                                                    <div role="status">
                                                        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                        </svg>
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <span className="inline-block overflow-hidden bg-gray-100 rounded-full h-14 w-14">
                                                    <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                    </svg>
                                                    </span>
                                                )}
                                            </div>


                                            <div className="flex gap-1">
                                                <p className="text-xs text-gray-500 max-w-[150px]"> Use your pic or business logo </p>

                                                <span class="rounded-md bg-white px-2.5 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Select</span>
                                                <input type="file" name="image" onChange={handleFileChange} accept="image/*" class="hidden" required />
                                            </div>
                                            </label>                                            
                                        </div>
                                    </div>
            
                                    <div className="flex items-center justify-end mt-6 gap-x-6">
                                        <SubmitButton submitting={submiting} buttonText="Send" sendingText="Sending..." />
                                    </div>
                                </div>
                            </form>
                    </div>
                }
            />
        )
    } else {
        return (
            <div className="h-full min-h-screen pb-10 bg-slate-300">
                            <ThankyouModal 
                                is_open={is_open_thankyou}
                                icon={
                                    <CheckCircleIcon className="w-20 h-20 text-indigo-400" />
                                }
                                button={
                                    <>
                                        <a
                                        href="/"
                                        className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-400 rounded-md shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        >
                                        Back To Home
                                        </a>
                                    </>
                                } 
                                message={
                                    <>
                                        <p className="text-2xl"> Thank you </p>
                                        <p> For submitting your review </p>
                                    </>

                                }  
                            />

                <div className="bg-white px-5 py-6 rounded-b-[40px] shadow-lg animate__backInDown animate__animated mx-5">
                    <a href="/" className="flex text-xs">
                        <ArrowLeftIcon className="w-4 h-4" />
                        </a>
                    <h2 className="mt-4 text-lg font-semibold leading-7">Review</h2>
                    <p className="mt-1 text-xs leading-6 text-gray-400"> 
                        Drop your experience with us. Your input will be valuable for us and guidance for new clients
                    </p>
                </div>
    
                <form onSubmit={submit} ref={reviewForm} className="px-5 mb-10 bg-slate-300">
            
                        <div className="grid grid-cols-1 mt-5 gap-x-6 gap-y-5 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                            <label htmlFor="mafa-client-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Name *
                            </label>
                            <div className="mt-2">
                                <input required type="text" name="name" id="mafa-client-name" placeholder='Enter your name'
                                className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-300 sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>
        
                            <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email *
                            </label>
                            <div className="mt-2">
                                <input required id="mafa-client-email" name="email" type="email" placeholder='Enter your email' autoComplete="email" className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-300 sm:text-sm sm:leading-6" />
                            </div>
                            </div>
        
                            <div className="col-span-full">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                Review *
                            </label>
                            <div className="mt-2">
                                <textarea id="about" name="message" rows={3} className="block w-full px-4 py-5 text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-300 sm:text-sm sm:leading-6" defaultValue={''} />
                            </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                    Image
                                </label>
                                <div className="flex mt-2">
                                    <label class="flex cursor-pointer items-center gap-x-5">

                                    <div className='overflow-hidden rounded-full'>
                                        {userImage ? (
                                            <img className="flex-shrink-0 w-16 h-16" src={userImage} alt="" />
                                        ) : is_image_upoading ? (
                                            <div role="status">
                                                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                </svg>
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <span className="inline-block overflow-hidden bg-gray-100 rounded-full h-14 w-14">
                                            <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                            </span>
                                        )}
                                    </div>

                                        <div className="flex gap-1">
                                            <p className="text-xs text-gray-500 max-w-[150px]"> Use your pic or business logo </p>

                                            <span class="rounded-md bg-white px-2.5 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Select</span>
                                            <input type="file" name="image" onChange={handleFileChange} accept="image/*" class="hidden" required />
                                        </div>

                                    </label>                                            
                                </div>
                            </div>
        
                        </div>

                        <div className="flex items-center justify-end">
                            <SubmitButton submitting={submiting} buttonText="Send" sendingText="Sending..." />
                        </div>
                </form>
            </div>
        )
    }
}
