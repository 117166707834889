import { UserCircleIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useState, useEffect } from 'react'
import { Carousel } from 'flowbite-react';
import 'animate.css';
import Master from './layout/MasterLayout';
import TestimonialModal from './modals/TestimonialDetails';
import { getTestimonial } from '../api/api';

export default function Testimonials() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [meta_data, setActiveData] = useState(null);
    const [testimonials, setMyData] = useState(null);
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

    useEffect(() => { const handleResize = () => { setScreenSize({ width: window.innerWidth }) };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const openModal = (data) => {
        setActiveData(data)
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setActiveData(null)
        setIsModalOpen(false);
    };

    useEffect(() => {
        (async () => {
          try {
                const data = await getTestimonial();
                setMyData(data.testimonial);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        })();
      }, []);    


    if (screenSize.width > 640) {

        return (
            <>
            { testimonials ? 
                <div id="services" className="py-10 my-10 bg-white rounded-[30px]">
                  <div className="px-6 mx-auto max-w-7xl lg:px-8">
                    <div className="max-w-2xl mx-auto text-center">
                      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Testimonials
                      </p>
                      <p className="mt-6 text-2xl font-semibold leading-8 text-gray-500">
                       What our clients say about us?.
                      </p>
                    </div>
                    <div className="max-w-4xl mx-auto mt-16 h-[400px]">
                    <Carousel  slideInterval={5000} 
                        leftControl={
                            <button
                            type="button"
                            className="rounded-full bg-white p-1.5 border border-slate-200 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                            <ChevronLeftIcon className="h-12 w-12 font-bold text-[60px] text-slate-400" aria-hidden="true" />
                        </button>
                        } 
                        rightControl={
                            <button
                            type="button"
                            className="rounded-full bg-white p-1.5 border border-slate-200 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                            <ChevronRightIcon className="h-12 w-12 font-bold text-[60px] text-slate-400" aria-hidden="true" />
                        </button>
                        } 

                    >
                        {testimonials.map((user) => (
                            <div className="flex items-center justify-center h-full">
                                <div key={user.name} className="flex justify-center rounded-lg">
                                    <div className="flex flex-col items-center justify-between text-center bg-[#FAFCFC] px-[120px] py-[50px]">
            
                                            <p className="text-gray-500 text-[28px] max-h-[125px] overflow-hidden">{user.message}</p>
                                            
                                            <div className='w-[50px] bg-slate-300 h-[8px] my-8 rounded-full'/>

                                        <div className='overflow-hidden rounded-full'>
                                            {user.image ? <img className="flex-shrink-0 w-28 h-28" src={'https://bucket.mafire.site/images/'+user.image} alt="" /> : <UserCircleIcon className="text-gray-400 w-28 h-28"/>  }
                                        </div>
            
                                        <div className="flex-1 px-4 py-2">
                                            <p className="font-medium text-gray-900"> {user.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                    </div>
                  </div>
                </div>
                : '' }
            </>
        )

    } else {

        return (
            <Master
                title = "Testimonials"
                child = {
                    <>
                        <TestimonialModal isOpen={isModalOpen} onClose={closeModal} data = {meta_data} />
    
                        <div>
                            { testimonials ? 
                            <ul className="grid grid-cols-1 gap-5 my-10 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                                    {testimonials.map((user) => (
                                        <li key={user.name} className="relative flex col-span-1 overflow-hidden rounded-md shadow-lg h-36 animate__animated animate__zoomIn">
                                        
                                        <div className="flex flex-col items-center justify-between min-w-full text-center bg-white border-t border-b border-r border-gray-200">
        
                                            <div className='mt-2 overflow-hidden rounded-full'>
                                                {user.image ? <img className="flex-shrink-0 w-10 h-10" src={'https://bucket.mafire.site/images/'+user.image} alt="" /> : <UserCircleIcon className="text-gray-600 w-9 h-9"/>  }
                                            </div>
        
                                            <div className="flex-1 px-4 py-2 text-sm">
                                                <p className="font-medium text-gray-900 hover:text-gray-600"> {user.name}</p>
                                                <p className="px-4 overflow-hidden text-gray-500 max-h-10">{user.message}</p>
                                            </div>
                                        </div>
        
                                        <button onClick={() => openModal(user)} className="absolute text-xs text-gray-400 underline right-2 bottom-1 ">
                                            More ..
                                        </button>
        
                                    </li>
                                    ))
                                }
                            </ul>
                             : '' }
                        </div>
                    </>
                }
            />
        )

    }
}
