import 'animate.css';
import Master from './layout/MasterLayout';
import Services from './Services';
import Testimonials from './Testimonials'
import Technologies from './Technologies'
import ContactUs from './ContactUs';
import About from "./About"
import { cards } from '../array';
import { useEffect, useState } from 'react';


export default function Home() {

  //   Handle Resize 
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

  useEffect(() => { const handleResize = () => { setScreenSize({ width: window.innerWidth }) };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (screenSize.width > 640) { 
    return (
      <Master
        child = {
            <div className="hidden sm:block lg:block">
              <About/>
              <Services/>
              <Technologies/>
              <Testimonials/>
              <ContactUs/>
            </div>
        }
      />
    )

  } else {
    return (
      <Master
        title="Welcom To Mafire"
        child = {
            <ul className="grid grid-cols-2 gap-6 my-16 sm:hidden lg:hidden">
              {cards.map((card, index) => (
              <li key={index}
                className={`col-span-1 rounded-lg bg-white shadow-lg relative 
                          ${ (index === 0 || index === 1) ? 'animate__animated animate__backInLeft' : '' } 
                          ${ (index === 2 || index === 3) ? 'animate__animated animate__backInRight' : '' }
                          `}
              >
                  <a href={'/'+card.link} className="flex items-center justify-between w-full p-6 space-x-6">
                    <div className="flex-1">
                      <card.icon className="flex-shrink-0 w-6 h-6 mr-4 text-[#38B6FF]" aria-hidden="true" />
                      <p className="mt-1 font-bold text-gray-700 truncate">{card.title}</p>
                      <p className="mt-2 text-sm text-gray-400">{card.description}</p>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
        }
      />
    )
  }
}
