import { useState, useEffect } from 'react'
import 'animate.css';
import Master from './layout/MasterLayout';
import ServiceModal from './modals/ServiceDetails';
import { services_array } from '../array';

export default function Services() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [meta_data, setActiveData] = useState(null);
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

    useEffect(() => { const handleResize = () => { setScreenSize({ width: window.innerWidth }) };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const openModal = (data) => {

        setActiveData(data)
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setActiveData(null)
        setIsModalOpen(false);
    };

    if (screenSize.width > 640) {

      return (
        <div id="services" className="hidden py-10 bg-white rounded-[30px] sm:block">
          <ServiceModal isOpen={isModalOpen} onClose={closeModal} data = {meta_data} />
          <div className="flex flex-col justify-center px-6 mx-auto max-w-7xl lg:px-8">
            <div className="max-w-2xl mx-auto text-center">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Services
              </p>
              <p className="mt-6 text-2xl font-semibold leading-8 text-gray-500">
                What we are providing you.
              </p>
            </div>
            <div className="max-w-4xl mx-auto mt-16">
              <ul className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {services_array.map((service) => (
                    <li key={service.name} className="relative grid col-span-1 animate__animated animate__zoomIn h-72">
                        
                        <a href={service.href} className="font-medium text-gray-900 hover:text-gray-600">
                            {service.name}
                        </a>
                        
                        <div className='col-span-1 p-5 border rounded-md shadow-lg border-slate-300'>
                            <img className="h-[200px] w-full" src={service.img} alt="" />
                        </div>
    
                        <button onClick={() => openModal(service)} className="absolute text-xs underline right-2 bottom-1 ">
                            Details ..
                        </button>
    
                    </li>
                    ))}
              </ul>
            </div>
          </div>
        </div>
      )

    } else {

      return (
        <Master
          title="Services"
          child = {
              <>
                <ServiceModal isOpen={isModalOpen} onClose={closeModal} data = {meta_data} />
    
                <div>
                    <ul className="grid grid-cols-1 gap-5 my-10 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                        {services_array.map((service) => (
                        <li key={service.name} className="relative flex col-span-1 rounded-md shadow-lg h-28 animate__animated animate__zoomIn">
                            
                            <div className='flex items-center justify-center flex-shrink-0 text-sm font-medium text-white bg-slate-100 w-22 rounded-l-md'>
                                <img className="flex-shrink-0 w-32 h-20" src={service.img} alt="" />
                            </div>
                            <div className="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md">
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                                <a href={service.href} className="font-medium text-gray-900 hover:text-gray-600">
                                {service.name}
                                </a>
                                <p className="text-gray-500 truncate">{service.description}</p>
                            </div>
                            </div>
    
                            <button onClick={() => openModal(service)} className="absolute text-xs text-gray-400 underline right-2 bottom-1 ">
                                More ..
                            </button>
    
                        </li>
                        ))}
                    </ul>
                </div>
            </>
          }
        />
      )
    }
}
