import Home from "./components/Home";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import About from "./components/About";

import Templates from "./components/Templates";
import ContactUs from "./components/ContactUs";
import News from "./components/News";
import Blogs from "./components/Blogs";
import Packages from "./components/Packages";
import Review from "./components/Review";
// import StickersBerryHome from "./stickers-berry/Home";


const routes = [
    { path: '/', component: <Home/> },
    { path: 'services', component: <Services/>},
    { path: 'testimonials', component: <Testimonials/>},
    { path: 'packages', component: <Packages/>},

    { path: 'samples', component: <Templates/>},
    { path: 'contact-us', component: <ContactUs/>},
    { path: 'news', component: <News/>},
    { path: 'blogs', component: <Blogs/>},
    { path: 'about-us', component: <About/>},
    { path: 'review-us', component: <Review/>},

    /** Sticker-Berry Routes */

    // { path: 'StickersBerry', component: <StickersBerryHome/>},

];

export default routes;
