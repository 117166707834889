import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { UserCircleIcon } from '@heroicons/react/20/solid'
export default function TestimonialModal({ isOpen, onClose, data }) {

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-hidden">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="overflow-y-auto max-h-[90vh] min-h-[70vh] w-full relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className ="sr-only">Close</span>
                    <XMarkIcon className="w-4 h-4" aria-hidden="true" />
                  </button>
                </div>
                  <div className=" rounded-2xl overflow-hidden flex justify-center">
                    <div className="rounded-full w-24 h-24 overflow-hidden">
                      {data ? (data.img ? <img className="h-auto max-w-full" src={data.img} alt="" /> : <UserCircleIcon className="w-20 h-20 text-gray-600"/> ) : "img"}
                    </div>
                  </div>
                  <div className="mt-4 sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 text-center">
                        {data ? data.name : ''}
                    </Dialog.Title>

                    <div className="mt-2 text-center">
                      <span class="bg-gray-200 text-gray-800 text-xs mr-2 px-2.5 py-0.5 rounded">
                        {data ? data.email : ''}
                      </span>
                    </div>

                    <div className="mt-10 text-center">
                      <p className="text-sm text-gray-500">
                        {data ? data.message : ''}
                      </p>
                    </div>
                  </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
