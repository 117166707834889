import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function ServiceModal({ isOpen, onClose, data }) {


  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-y-auto max-h-[90vh] w-full relative border-[#38B6FF] border-2 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:max-w-[900px]">
                
                <div className="flex justify-center text-center">
                  <p className="px-10 py-2 font-semibold text-white bg-[#38B6FF] rounded-b-sm "> {data ? data.name : ''} </p> 
                </div>
               
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className ="sr-only">Close</span>
                    <XMarkIcon className="w-4 h-4" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="px-5 sm:ml-4 sm:mt-0 sm:text-left">
                    <p className="py-5 text-base font-semibold text-gray-500 sm:text-2xl">
                      {data ? data.description : ''}
                    </p>

                    <div className='w-full h-[1px] bg-gray-300 mt-2'></div>

                    <ul className="mt-5">
                        {data ? data.points.map((point,index) => (
                            <li key={index} className="text-left">
                            <p className="pl-2 my-4 text-sm text-gray-500 border-l-4 border-gray-300"> <span className="font-semibold text-gray-700"> {point.title} : </span> {point.detail} </p>
                            </li>
                        )) : ''}
                    </ul>
                  </div>
                </div>
                {/* <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
