import { contactForm } from '../api/api';
import { ArrowLeftIcon, CheckCircleIcon  } from '@heroicons/react/24/outline'
import SubmitButton from '../utils/button';
import { useState } from 'react';
import ThankyouModal from '../utils/thankyouModal';


export default function ContactUs() {
  const [submiting, is_submitting] = useState(false);
  const [is_open_thankyou, is_thankyou] = useState(false);
  
  const submit = async (e)  => {
    is_submitting(true);
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {};

    formData.forEach((value, key) => {
      data[key] = value;
    });

    try {

      await contactForm(data);
      is_submitting(false);
      is_thankyou(true);
    
    } catch (error) {

      console.log(error);
      is_submitting(false);
    }

  }
  return (

        <div id="contactus" className="h-screen min-h-full px-5 pb-10 sm:px-0 sm:py-10 sm:h-full lg:h-full bg-slate-300 sm:flex sm:rounded-lg">
           <ThankyouModal 
                is_open={is_open_thankyou}
                icon={
                    <CheckCircleIcon className="w-20 h-20 text-[#38B6FF]" />
                }
                button={
                    <a
                        href="/"
                        className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-[#38B6FF] rounded-md shadow-sm sm:ml-3 sm:w-auto"
                    >
                        Refesh
                    </a>
                } 
                message={
                    <>
                        <p className="text-2xl"> Thank you </p>
                        <p> For contacting us </p>
                    </>

                }  
            />
            <div className="sm:hidden bg-white px-5 py-6 rounded-b-[40px] sm:rounded-[30px] shadow-lg animate__backInDown animate__animated">
                <a href="/" className="flex text-xs sm:hidden">
                    <ArrowLeftIcon className="w-4 h-4" />
                </a>
              <h2 className="mt-4 text-lg font-semibold leading-7 sm:text-4xl">Contact Us</h2>
              <p className="mt-1 text-xs leading-6 text-gray-400">Connect with us effortlessly – this is your gateway to seamless communication and personalized support.</p>
            </div>

            <div className="hidden sm:block bg-white px-5 py-6 rounded-b-[40px] sm:rounded-[30px] shadow-lg animate__backInDown animate__animated">
              <h2 className="mt-4 text-lg font-semibold leading-7 sm:text-4xl">Contact Us</h2>
              <p className="mt-4 leading-6 text-gray-500 max-w-[600px]">Embark on a journey of unparalleled connection with us, where seamless communication and personalized support converge to create an effortless gateway into a world of unparalleled service. We strive to make every interaction a smooth and tailored experience, ensuring that your needs are not only met but exceeded. Join us on this path to connectivity, where every touchpoint becomes a bridge to understanding and addressing your unique requirements. Your satisfaction is our priority, and this gateway is designed to be your direct link to a harmonious and efficient partnership. Welcome to a realm where connection meets convenience, and support is synonymous with understanding.</p>
            </div>

            <form className="sm:flex sm:flex-col sm:justify-center sm:w-1/2 sm:p-5 " onSubmit={submit}>
              <div className="space-y-12">

                <div className="grid grid-cols-1 mt-10 gap-x-6 gap-y-5">
                  <div className="sm:col-span-3">
                    <label htmlFor="mafa-client-name" className="block text-sm font-medium leading-6 text-gray-900">
                      Name *
                    </label>
                    <div className="mt-2">
                      <input
                        required
                        type="text"
                        name="name"
                        id="mafa-client-name"
                        placeholder='Enter your name'
                        className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email *
                    </label>
                    <div className="mt-2">
                      <input
                        required
                        id="mafa-client-email"
                        name="email"
                        type="email"
                        placeholder='Enter your email'
                        autoComplete="email"
                        className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                      Message *
                    </label>
                    <div className="mt-2">
                      <textarea
                        required
                        id="about"
                        name="message"
                        rows={3}
                        className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                      />
                    </div>
                  </div>

                </div>

                <div className="flex items-center justify-end mt-6 gap-x-6">
                  <SubmitButton submitting={submiting} buttonText="Send" sendingText="Sending..." />
                </div>

              </div>
            </form>
        </div>
        
  )
}
