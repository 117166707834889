import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline'
export default function PackageDetails({ isOpen, onClose, data }) {

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-hidden">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">

              <Dialog.Panel className="overflow-y-auto max-h-[90vh] w-full relative border-[#38B6FF] border-2 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:max-w-lg">
                
                <div className="flex justify-center text-center">
                  <p className="px-10 py-2 font-semibold text-white bg-[#38B6FF] rounded-b-sm"> {data ? data.type : ''} </p> 
                </div>
                
                
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className ="sr-only">Close</span>
                    <XMarkIcon className="w-4 h-4" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">

                  <div className="mt-3 text-center sm:ml-4 sm:mt-0">

                    <p class="text-[#38B6FF] text-[50px] font-semibold ml-4 mt-2">
                      {data ? data.price : ''}
                    </p>

                    <div className='w-full h-[1px] bg-gray-300 mt-2'></div>

                    <ul className="px-5 mt-5 ml-2">
                        {data ? data.description.map((text,index) => (
                            <li key={index} className="text-left">
                              <div className='flex items-center text-left'>
                                <div className='p-1 mr-4 bg-gray-200 rounded-full'>
                                  <CheckIcon className="w-4 h-4" aria-hidden="true" />
                                </div>
                                <p className="my-4 text-sm text-gray-500"> {text.name} <span> {text.detail ? <p className="text-xs text-gray-300"> {text.detail} </p> : ''} </span>  </p>
                              </div>
                            </li>
                        )) : ''}
                    </ul>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
