import Header from "./Header"
import Footer from "./Footer"
import { useEffect, useState } from 'react';



export default function Master({title,child}) {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

  useEffect(() => { const handleResize = () => { setScreenSize({ width: window.innerWidth }) };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (screenSize.width > 640) { 
      return (             
    
        <div className="min-h-screen">

            <Header/>

            <main className="relative bg-slate-300 ">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="px-5 py-6 sm:px-6">
                        {child}
                    </div>
                </div>
            </main>

            <div className="px-5 bg-slate-300">
                <div as="nav" className="flex items-center justify-center py-5 bg-white rounded-t-3xl">
                        <p> Copyright ©️ 2023 , Powered By  </p>
                        <img className="w-[120px] ml-2" src="/images/small-logo.png"/>

                    {/* <div>
                        <p className="mb-2"> join our community to get updated </p>
                        <div className="flex gap-3">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Email"
                            />

                            <button
                                type="button"
                                className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Subscribe
                            </button>
                        </div>
                    </div> */}


                </div>
            </div>
        </div>
          
      )

  } else {
    return (
        <>

        <Header title = {title} />

        <div className="relative flex flex-col h-screen px-4 bg-slate-300">
            <div className="overflow-y-auto min-h-[435px]">
                {child}
            </div>
        </div>

        <div className="sticky bottom-0 bg-slate-300">
            <Footer/>
        </div>
        </>
    )
  }
}