import 'animate.css';

import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Master from './layout/MasterLayout';
import { packages_array } from '../array';
import PackagesModal from './modals/PackagesDetails';

export default function Packages() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [meta_data, setActiveData] = useState(null);
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

    useEffect(() => { const handleResize = () => { setScreenSize({ width: window.innerWidth }) };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const openModal = (data) => {
        setActiveData(data)
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setActiveData(null)
        setIsModalOpen(false);
    };

    if (screenSize.width > 640) {
        return (
            <Master
                title="Packages"
                child = {
                    <>
                   {packages_array.map((pkg,index)=>(
                        <div id="packages" className="py-10 my-10 bg-white rounded-[30px]">
                            <div key={index} className="flex flex-col justify-center px-6 mx-auto max-w-7xl lg:px-8">
                                <div className="max-w-2xl mx-auto lg:text-center">
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                        {pkg.name}
                                    </p>
                                </div>
                                <section className="bg-white">
                                    <div className="max-w-screen-xl px-4 py-8 lg:py-16 lg:px-6">
                                        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                                            {pkg.packages.map((item)=> (
                                                <div className="flex flex-col p-6 m-5 mx-auto text-center text-gray-900 border border-gray-100 rounded-lg animate__flipInY animate__animated" style={{boxShadow:'0px 2px 20px 0px #6b7280'}}>
                                                    <h3 className="mb-4 text-2xl font-semibold">{item.type}</h3>
                                                    <div className="flex items-baseline justify-center my-8">
                                                        <span className="mr-2 text-5xl font-extrabold">{item.price}</span>
                                                    </div>

                                                    <ul className="mb-8 space-y-4 text-left">
                                                        {item.description.map((list,index) => (
                                                            <li key={index} className="flex items-center space-x-3">
                                                                <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                                                <p className="text-gray-500 ext-sm "> {list.name} <span> {list.detail ? <p className="text-xs text-gray-300"> {list.detail} </p> : ''} </span>  </p>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    ))}
                    </>
                    }
            />
        )

    } else {
        
        return (
            <Master
                title="Packages"
                child = {
                    <>
                        <PackagesModal isOpen={isModalOpen} onClose={closeModal} data = {meta_data} />

                        <div className="flex flex-col my-20">
                        {packages_array.map((pkg)=>(
                            <Menu as="div" className="relative inline-block my-2 text-left animate__animated animate__zoomIn">
                                <div>
                                    <Menu.Button className="flex w-full justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                        
                                        <div className="text-left">
                                            <p> {pkg.name} </p>
                                            <p className="text-xs text-gray-400"> {pkg.description} </p>
                                        </div>
                                        <ChevronDownIcon className="w-5 h-5 -mr-1 text-gray-400" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-10 w-full my-2 origin-top-right bg-white divide-y-2 rounded-md shadow-lg divide ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {pkg.packages.map((item)=> (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={() => openModal(item)}
                                                            href="https://www.google.com"
                                                            className='block w-full px-4 py-2 text-sm text-left text-gray-900'>
                                                            {item.type}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                </Transition>
                            </Menu>
                        ))}
                        </div>
                    </>
                }
            />
        )
    }
}
